import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { FormErrorModel } from 'projects/ig-core/src/lib/form/form-error.model';
import { IgFormService } from 'projects/ig-core/src/lib/form/form.service';
import { takeUntil } from 'rxjs/operators';
import { Questionaire } from './questionaire.model';
import { QuestionaireService } from './questionaire.service';
import { ReferenceCodeService } from 'src/app/admin/reference-code/reference-code.service';
import { QuestionService } from 'src/app/entities/question/question.service';

@Component({
    selector: 'app-questionaire-creation',
    templateUrl: './questionaire-editor.component.html'
})
export class QuestionaireEditorComponent implements OnInit, OnDestroy {
    questionaire: Questionaire;
    editable: true;
    isPresent: boolean;
    isSave: boolean;
    editOrView: string;
    referenceCodeList = [];
    businessCodeList = [];
    questionnaireCodeList = [];
    public questionaireEditorForm: FormGroup;
    public formError: FormErrorModel;
    public formErrors = {
        name: '',
        description: '',
        questionaireVersionName: '',
        questionaireVersionCode: '',
        version: '',
        code: '',
        questionnaireVersionName:'',
        questionnaireVersionCode:'',
        refernceModuleCode:'',
        businessCode:''
    };
    constructor(private questionaireService: QuestionaireService,
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private igFormService: IgFormService,
        private router: Router,
        private questionService: QuestionService,
        private referenceCodeService: ReferenceCodeService) {
        this.activatedRoute.data
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((data) => {
                if (data.questionaire === undefined) {
                    this.isSave = true;
                    this.questionaire = {};
                } else {
                    this.isPresent = true;
                    this.questionaire = data.questionaire;
                }
                this.editable = data.editable;
            });

    }

    ngOnInit() {
        this.loadModuleMastersCodes();
        this.loadBusinessCode();
        this.buildForm();
        this.formError = new FormErrorModel(false, '');
        this.editOrView = 'Edit';
        if (!this.editable) {
            this.questionaireEditorForm.disable();
            this.editOrView = 'View';
        }
        this.questionService.setQuestionSequnces(this.questionaire.questions);
    }

    ngOnDestroy(): void {
    }

    buildForm() {
        this.questionaireEditorForm = this.formBuilder.group({
            id: [this.questionaire.id],
            name: [{value:this.questionaire.name ? this.questionaire.name : '', disabled:this.questionaire.id}, Validators.required],
            description: [this.questionaire.description],
            questionnaireVersionName: [this.questionaire.questionnaireVersionName, Validators.required],
            questionnaireVersionCode: [this.questionaire.questionnaireVersionCode, Validators.required],
            version: [this.questionaire.version],
            code: [{value:this.questionaire.code ? this.questionaire.code : '', disabled:this.questionaire.id}, Validators.required],
            status: [this.questionaire.status],
            refernceModuleCode: [{value:this.questionaire.refernceModuleCode ? this.questionaire.refernceModuleCode : '', disabled:this.questionaire.refernceModuleCode}, Validators.required],
            businessCode: [{value:this.questionaire.businessCode ? this.questionaire.businessCode : '', disabled:this.questionaire.businessCode}, Validators.required]
            
        });
        
        this.questionaireEditorForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
            this.formErrors = this.igFormService.validateForm(this.questionaireEditorForm, this.formErrors, true);
        });
    }

    onSubmit() {
        this.formError = new FormErrorModel(false, '');
        this.igFormService.markFormGroupTouched(this.questionaireEditorForm);
        if (this.questionaireEditorForm.valid) {
            this.questionaireEditorForm.get('name')?.enable();
            this.questionaireEditorForm.get('code')?.enable();
            this.questionaireEditorForm.get('refernceModuleCode')?.enable();
            this.questionaireEditorForm.get('businessCode')?.enable();
            this.questionaireService.saveQuestionaire(this.questionaireEditorForm.value).pipe(takeUntil(componentDestroyed(this))).
                subscribe((response) => this.onSubmitSuccess(response),
                    (response) => this.onSubmitError(response));
        } else {
            this.formErrors = this.igFormService.validateForm(this.questionaireEditorForm, this.formErrors, false);
            return false;
        }
    }

    private onSubmitSuccess(response) {
        this.snackBar.open(`Updated questionaire '${response.body.questionnaireVersionCode}'
        of version '${response.body.questionnaireVersionName}' successfully`, 'Close', {
            duration: 3000,
        });
        this.goToQuestionaireListPage();
    }

    private onSubmitError(response) {
        const errorModel = this.igFormService.handleServerError(this.questionaireEditorForm, this.formErrors, response.error);
        if (errorModel && errorModel.isValidationError) {
            this.formErrors = errorModel.formErrors;
        } else if (errorModel && !errorModel.isValidationError) {
            this.formError.hasError = true;
            this.formError.error = errorModel.otherError;
        }
    }
    goToQuestionaireListPage() {
        this.router.navigateByUrl('questionaire');
    }
    createQuestion() {
        this.router.navigate(['questionaire/' + this.questionaire.id + '/question/create']);
    }

    loadModuleMastersCodes(){
    this.referenceCodeService.getModuleMastersCodes('MODULE_MASTER')
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((response) => {
        this.referenceCodeList = response;
      });
    }

    loadBusinessCode(){
        this.referenceCodeService.getBusnisseCodes('SUBMODULE_MASTER')
          .pipe(takeUntil(componentDestroyed(this)))
          .subscribe((response) => {
            this.businessCodeList = response;
          });
        }
        
    loadRefcodes() {
        this.referenceCodeService.getQuestionaireCodes().pipe(takeUntil(componentDestroyed(this))).
            subscribe(response => this.questionnaireCodeList = response);
    }

}
