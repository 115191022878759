import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { branchSetRoute } from './branch-set/branch-set.module';
import { branchRoute } from './branch/branch.module';
import { referenceCodeRoute } from './reference-code/reference-code.module';
import { roleRoute } from './role/role.module';
import { userRoute } from './user/user.module';
import { centerRoute } from './center/center.module';
import { appDataRoute } from './app-data/app-data.module';
import { questionnaireConfigurationRoute } from './questionnaire-configuration/questionnaire-configuration.module';

import { applicationUsageStatsRoute } from './application-usage-stats/application-usage-stats.module';
import { masterfilteruploadRoute } from './questionnaire-configuration/master-filter-upload/master-filter-upload.module';
const routes: Routes = [
    {
        path: 'admin',
        children: [userRoute, branchRoute, branchSetRoute, roleRoute, referenceCodeRoute,
            centerRoute,questionnaireConfigurationRoute, masterfilteruploadRoute, appDataRoute, applicationUsageStatsRoute]
    }];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class AdminRoutingModule { }
