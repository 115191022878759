import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { IgCoreModule } from 'projects/ig-core/src/lib/ig-core.module';
import { MultiLevelMenuModule } from 'projects/multi-level-menu/src/lib/multi-level-menu.module';
import { AdminModule } from './admin/admin.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgConfigureModule } from './build-config/configure.module';
import { EntityModule } from './entities/entity.module';
import { HomeModule } from './home/home.module';
import { IgLayoutModule } from './ig-layout/ig-layout.module';
import { MaterialLibModule } from './material-lib.module';
import { UserAccountModule } from './user-account/user-account.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {ChartsModule} from 'ng2-charts';



@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgConfigureModule.forRoot(),
    FlexLayoutModule.withConfig({useColumnBasisZero: false}),
    MaterialLibModule,
    ReactiveFormsModule,
    IgCoreModule,
    LayoutModule,
    IgLayoutModule,
    HttpClientModule,
    NgHttpLoaderModule, /*https://github.com/mpalourdio/ng-http-loader*/
    MultiLevelMenuModule,
    HomeModule,
    UserAccountModule,
    AdminModule,
    EntityModule,
    DashboardModule,
    ChartsModule
  ],
  providers: [

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
