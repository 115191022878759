import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {MatSelect} from '@angular/material/select';
import {MatCheckbox} from '@angular/material/checkbox';
import {ActivatedRoute, Router} from '@angular/router';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
import {ListSource} from 'projects/ig-core/src/lib/list/list-source';
import {PagingAndSortParams} from 'projects/ig-core/src/lib/list/paging-and-sort-params';
import {takeUntil} from 'rxjs/operators';
import {Role} from '../role/role.model';
import {RoleService} from '../role/role.service';
import {AppDataDataSource} from './app-data.data.source';
import {AppDataService} from './app-data.service';
import { UserService } from '../user/user.service';
import { appStatusList } from 'src/app/constants/data.constants';
import { UserDetailsComponent } from './user-details.component';

@Component({
    selector: 'app-app-data',
    templateUrl: './app-data.component.html',
    styleUrls: ['./app-data.component.css']
})
export class AppDataComponent implements OnInit, AfterViewInit, OnDestroy {
    appDataSearchParams: AppDataComponent;
    pagingAndSortParams: PagingAndSortParams;
    dataSource: AppDataDataSource;
    listSource: ListSource;
    roles: Role[];
    role: string;
    status: string;
    businessLines: number;
    selectedRoles: string[];
    isActiveOrInactive: string;
    userStatusList = ['ACTIVE', 'INACTIVE'];
    statusList = ['INSTALLED', 'NOT-INSTALLED'];
    businessLinesList =[];
    appStatus: string;
    appLists=appStatusList;
    dialogRefForuser:MatDialogRef<UserDetailsComponent>;
  

    displayedColumns = ['stateName', 'branchName', 'login', 'firstName', 'role', 'appVersion',
    'cashMangementVersion','cashMangementPackage', 'lastLogin', 'appInstalledOn', 'action'];

      @ViewChild(MatPaginator,{static:true})
    paginator: MatPaginator;

    @ViewChild(MatSort,{static:true})
    sort: MatSort;
    @ViewChild('roleSelect',{static:false}) roleSelect: MatSelect;
    @ViewChild('roleAllSelect',{static:false}) roleAllSelect: MatCheckbox;
element: any;

    constructor(private appDataService: AppDataService,
                private activatedRoute: ActivatedRoute,
                private router: Router, private snackBar: MatSnackBar,
                private dialog: MatDialog, private roleService: RoleService,
                private userService: UserService) {
    }

    ngOnInit() {
        this.businessLines = 1;
        this.loadBusinessLine();
        this.roleService.getAllRoles().pipe(takeUntil(componentDestroyed(this))).subscribe(roles => {
            this.roles = roles.body;
        });
        this.dataSource = new AppDataDataSource(this.appDataService);
        this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);
        this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this)))
            .subscribe((listParams) => {
                this.pagingAndSortParams = listParams.pagingAndSortParam;
                let sortColumn = this.pagingAndSortParams.sortColumn;
                if (!sortColumn || sortColumn === '' || sortColumn === undefined) {
                    sortColumn = 'name';
                }
                this.loadAppDatas(sortColumn);
            });
        this.loadAppDatas('');
       
    }

    ngOnDestroy(): void {
        this.listSource.disconnect();
    }

    ngAfterViewInit(): void {
        // initiate sort and pagination change once the view is initialized
        this.listSource.subscribePaginationAndSortChange()
            .pipe(takeUntil(componentDestroyed(this))).subscribe(() => this.loadAppDatasPageWithParams());
    }

    loadAppDatasPageWithParams() {
        this.router.navigate(['admin/app-data'], this.getQueryParams());
    }

    loadAppDatas(sortColumn: String) {
        this.dataSource.loadAppDatas(sortColumn + ',' + this.pagingAndSortParams.sortDirection,
            this.pagingAndSortParams.pageIndex, this.pagingAndSortParams.pageSize, this.status,
            this.selectedRoles, this.isActiveOrInactive, this.businessLines, this.appStatus);
    }

    getQueryParams() {
        const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
        return queryParams;
    }

    downloadAppDatas() {
        this.appDataService.downloadAppData({
            status: this.status,
            selectedRoles: this.selectedRoles,
            userStatus: this.isActiveOrInactive,
            appStatus: this.appStatus,
            businessLineId: this.businessLines,
        }).pipe(takeUntil(componentDestroyed(this))).subscribe(res => {
            console.log('start download:', res);
            const url = window.URL.createObjectURL(res.data);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = res.filename;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove(); // remove the element
        }, error => {
            console.log('download error:', JSON.stringify(error));
        }, () => {
            console.log('Completed file download.');
        });
    }

    clearFilter() {
        this.status = undefined;
        this.isActiveOrInactive = undefined;
        this.selectedRoles = undefined;
        this.roleAllSelect.checked = undefined;
        this.loadAppDatas('');
        this.businessLines = 1;
        this.loadBusinessLine();
        this.appStatus = undefined;
     }

    onAllChanged(checked: any, allValues: any, selectId: any, selectType: string) {
        const filterData = [];
        if (checked) {
            if (selectType === 'role') {
                allValues.forEach(value => {
                    filterData.push(value.code);
                });
                this.selectedRoles = filterData;
            }
            selectId.value = filterData;
        } else {
            if (selectType === 'role') {
                this.selectedRoles = [];
            }
            selectId.value = [];
        }
    }

    selectionChanged(selectAllCheckbox: any, selectedValues: any, allValues: any) {
        if (selectedValues.length === allValues.length) {
            selectAllCheckbox.checked = true;
        } else {
            selectAllCheckbox.checked = false;
        }
    }
    //to get the data in the business Line dropdown
    loadBusinessLine(){
        this.userService.getBusinessLine().pipe(takeUntil(componentDestroyed(this))).subscribe(response => {
            this.businessLinesList= response;
          });
    }
    getAllUserDetails(element: any){
        this.dialogRefForuser= this.dialog.open(UserDetailsComponent, {
            width: '700px',
            data: {element: element},
        });

    }
}

