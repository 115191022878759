import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { IgLayoutService } from '../ig-layout.service';
import { MenuItem } from 'projects/multi-level-menu/src/lib/menu-item';
import { PrincipalService } from 'projects/ig-core/src/lib/authentication/principal.service';
import { delay, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
    selector: 'ig-layout-two-column',
    templateUrl: './two-column.component.html',
    styleUrls: ['./two-column.component.css']
})
export class TwoColumnComponent implements OnInit, OnDestroy {

    @ViewChild('sNav',{static:true})
    public sideNav: MatSidenav;
    public isAuthenticated = false;
    mobileQuery: MediaQueryList;
    loggedInUser: any;

    private _mobileQueryListener: () => void;

    menuItems: MenuItem[] = [
        {
            displayName: 'Home',
            iconName: 'home',
            route: 'home'
        },
        {
            displayName: 'Admin User',
            iconName: 'apps_admin',
            children: [
                {
                    displayName: 'User',
                    iconName: 'face',
                    route: 'admin/user',
                    roles: ['ROLE_ADMIN', 'ROLE_VIEW_USER_LIST'],
                },
                {
                    displayName: 'Role',
                    iconName: 'group',
                    route: 'admin/role',
                    roles: ['ROLE_ADMIN', 'ROLE_VIEW_ROLE_LIST'],
                },
                {
                    displayName: 'Branch',
                    iconName: 'donut_large',
                    route: 'admin/branch',
                    roles: ['ROLE_ADMIN', 'ROLE_VIEW_BRANCH_LIST'],
                },
                {
                    displayName: 'Branch Set',
                    iconName: 'donut_small',
                    route: 'admin/branch-set',
                    roles: ['ROLE_ADMIN', 'ROLE_VIEW_BRANCHSET_LIST'],
                },
                {
                    displayName: 'Center',
                    iconName: 'location_city',
                    route: 'admin/center',
                    roles: ['ROLE_ADMIN', 'ROLE_VIEW_CENTER_LIST']
                },
                {
                    displayName: 'Reference Code',
                    iconName: 'code',
                    route: 'admin/reference-code',
                    roles: ['ROLE_ADMIN', 'ROLE_VIEW_REFRENCE_CODE_LIST']
                },
                {
                    displayName: 'Questionnaire Configuration',
                    iconName: 'arrow_drop_down_circle',
                    children: [
                        {
                            displayName: 'Management Questionnaire Configuration',
                            iconName: 'settings_applications',
                            route: 'admin/questionnaire-configuration',
                            roles: ['ROLE_ADMIN', 'ROLE_SHOW_QUSESTIONNAIRE_CONFIGURATION'],
 
                        },
                        {
                            displayName: 'Master Filter Upload',
                            iconName: 'insert_drive_file',
                            route: 'admin/master-filter-upload',
                            roles: ['ROLE_ADMIN', 'ROLE_SHOW_MASTER_FILTER_UPLOAD'],
                        },
                    ]
                },
               
            ]
        },
        {
            displayName: 'BMT Dashboard',
            iconName: 'dashboard',
            children: [
                {
                    displayName: 'Questionnaire Report',
                    iconName: 'cloud_download',
                    route: 'questionaire/questionaireReport',
                    roles: ['ROLE_ADMIN', 'ROLE_SHOW_QUESTIONNAIRE_REPORT']
                },
                {
                    displayName: 'Activity Tracker Report',
                    iconName: 'explore',
                    route: 'dashboard/activityTrackerReport',
                    roles: ['ROLE_ADMIN', 'ROLE_SHOW_ACTIVITYTRACKER_REPORT']
                },
                /*{
                    displayName: 'Productivity Dashboard',
                    iconName: 'bar_chart',
                    route: 'dashboard/productivityDashboard'
                },*/
                {
                    displayName: 'Efficiency Report',
                    iconName: 'trending_up_icon',
                    route: 'dashboard/efficiencyReport',
                    roles: ['ROLE_ADMIN', 'ROLE_SHOW_EFFICIENCY_REPORT']
                },
                {
                    displayName: 'Deviation Report',
                    iconName: 'call_split',
                    route: 'dashboard/deviationReport',
                    roles: ['ROLE_ADMIN', 'ROLE_SHOW_DEVIATION_REPORT']
                }/*,
                {
                    displayName: 'Branch Visit Monitor',
                    iconName: 'visibility',
                    route: 'dashboard/branchvisitmonitor'
                }*/
            ]
        },
        {
            displayName: 'Audit Dashboard',
            iconName: 'dashboard',
            children: [
                {
                    displayName: 'Reports',
                    iconName: 'library_books',
                    route: 'dashboard/report',
                    roles: ['ROLE_ADMIN', 'ROLE_SHOW_AUDITDASHBOARD'],
                },
            ]
        },
        {
            displayName: 'Jobs Dashboard',
            iconName: 'dashboard',
            children: [
                {
                    displayName: 'File Upload',
                    iconName: 'insert_drive_file',
                    route: 'dashboard/jobFileUpload',
                    roles: ['ROLE_ADMIN', 'ROLE_SHOW_JOBDASHBOARD'],
                },
                {
                    displayName: 'Import History',
                    iconName: 'history',
                    route: 'dashboard/import',
                    roles: ['ROLE_ADMIN', 'ROLE_SHOW_JOBDASHBOARD'],
                }
            ]
        },
        { 
            displayName: 'RAG Dashboard',
            iconName: 'dashboard',
            children:[
                {
                    displayName: 'RAG Report',
                    iconName: 'trending_up',
                    route: 'dashboard/ragReport',
                    roles: ['ROLE_ADMIN', 'ROLE_RAG_DASHBOARD'],
                },

            ]
        },
        {
            displayName: 'Stats',
            iconName: 'bar_chart',
            children: [
                {
                    displayName: 'App Data',
                    iconName: 'android',
                    route: 'admin/app-data',
                    roles: ['ROLE_ADMIN', 'ROLE_SHOW_APPSDATA'],
                },
                {
                    displayName: 'App Usage Log',
                    iconName: 'list_alt',
                    route: 'admin/application-usage-stats',
                    roles: ['ROLE_ADMIN', 'ROLE_SHOW_STATSDATA'],
                },
            ]
        },
        {
            displayName: 'Hr Dashboard',
            iconName: 'dashboard',
            children: [
                {
                    displayName: 'Hr Report',
                    iconName: 'cloud_download',
                    route: 'dashboard/hrReport',
                    roles: ['ROLE_ADMIN', 'ROLE_SHOW_HR_DASHBOARD'],
                },
            ]
        },
        {
            displayName: 'Questionnaire',
            iconName: 'phonelink_setup',
            route: 'questionaire',
            roles: ['ROLE_ADMIN', 'ROLE_LIST_QUESTIONNAIRE', 'ROLE_CREATE_QUESTIONNAIRE', 'ROLE_EDIT_QUESTIONNAIRE', 'ROLE_VIEW_QUESTIONNAIRE'],
        },
        {
            displayName: 'Activity Configuration',
            iconName: 'mobile_friendly',
            route: 'activity-role',
            roles: ['ROLE_ADMIN', 'ROLE_LIST_ACTIVITYCONFIG', 'ROLE_CREATE_ACTIVITYCONFIG', 'ROLE_EDIT_ACTIVITYCONFIG', 'ROLE_VIEW_ACTIVITYCONFIG'],
        },
        {
            displayName: 'Audit Plan',
            iconName: 'event_note',
            route: 'auditPlan',
            roles: ['ROLE_ADMIN', 'ROLE_AUDIT_SU', 'ROLE_VIEW_AUDIT_PLAN_LIST', 'ROLE_CREATE_AUDIT_PLAN', 'ROLE_EDIT_AUDIT_PLAN', 'ROLE_VIEW_AUDIT_PLAN']
        },
        {
            displayName: 'Audit Transaction',
            iconName: 'list_alt',
            route: 'auditTransaction',
            roles: ['ROLE_ADMIN', 'ROLE_AUDIT_SU', 'ROLE_VIEW_AUDIT_TRANSACTIONS', 'ROLE_AUDIT_TRANSACTIONS_HISTORY', 'ROLE_AUDIT_TRANSACTIONS_CHECKLIST']
        },
        {
            displayName: 'Moratorium Dashboard',
            iconName: 'dashboard',
            children: [
                {
                    displayName: 'Reports',
                    iconName: 'library_books',
                    route: 'dashboard/moratorium',
                    roles: ['ROLE_ADMIN', 'ROLE_SHOW_MORATORIUM_REPORT'],
                },
                {
                    displayName: 'Consent Form',
                    iconName: 'file_copy',
                    route: 'dashboard/moratoriumConsent',
                    roles: ['ROLE_ADMIN', 'ROLE_SHOW_MORATORIUM_CONCENT_REPORT'],
                },
            ]
        }, {
            displayName: 'Digital Dashboard',
            iconName: 'dashboard',
            children: [
                {
                    displayName: 'Digital Reports',
                    iconName: 'important_devices',
                    route: 'dashboard/cihreport',
                    roles: ['ROLE_ADMIN', 'ROLE_SHOW_DIGITAL_REPORT'],
                },
            ]
        },
        {
            displayName: 'CRM Dashboard',
            iconName: 'dashboard',
            children: [
                {
                    displayName: 'CRM File Upload',
                    iconName: 'library_books',
                    route: 'dashboard/crmfileupload',
                    roles: ['ROLE_ADMIN', 'ROLE_SHOW_CRM_FILE_UPLOAD'],
                },
                {
                    displayName: 'CRM Questionnair Report',
                    iconName: 'cloud_download',
                    route: 'dashboard/creditriskmodel',
                    roles: ['ROLE_ADMIN', 'ROLE_SHOW_CRM_DASHBOARD'],

                },
            ]
        }, {
            displayName: 'Hard Bucket Dashboard',
            iconName: 'dashboard',
            children: [
                {
                    displayName: 'Hard Bucket Collection Reports',
                    iconName: 'library_books',
                    route: 'dashboard/hardbucketcollection',
                    roles: ['ROLE_ADMIN', 'ROLE_SHOW_HARD_BUCKET_DASHBOARD'],
                },
            ]
        },
        {
            displayName: 'Report Dashboard',
            iconName: 'dashboard',
            children: [
                {
                    displayName: 'Deviation Reports',
                    iconName: 'library_books',
                    route: 'dashboard/crossfunctionaldeviation',
                    roles: ['ROLE_ADMIN', 'ROLE_SHOW_REPORT_DASHBOARD'],
                },
            ]
        },
        {
            displayName: 'Score Card Dashboard',
            iconName: 'dashboard',
            children: [
                {
                    displayName: 'Score Card',
                    iconName: 'score',
                    route: 'dashboard/scorecard',
                    roles: ['ROLE_ADMIN', 'ROLE_SHOW_SCORE_CARD'],
                },
                {
                    displayName: 'Score Card Setup',
                    iconName: 'dns',
                    route: 'scorecard',
                    roles: ['ROLE_ADMIN', 'ROLE_SHOW_SCORE_CARD_LIST'],
                },

            ]
        },
        {

            displayName: 'Activity Dashboard',
            iconName: 'dashboard',
            children: [
                {
                    displayName: 'Activity Reports',
                    iconName: 'supervisor_account',
                    route: 'dashboard/activityreport',
                    roles: ['ROLE_ADMIN', 'ROLE_SHOW_MANDATORY_REPORT_DASHBOARD'],
                },
                {
                    displayName: 'Upload Employee Leave Details',
                    iconName: 'supervised_user_circle',
                    route: 'dashboard/employeeleavedetails',
                    roles: ['ROLE_ADMIN', 'ROLE_SHOW_MANDATORY_USER_UPLOAD_DASHBOARD'],
                },

            ]
        },
    ];

    constructor(changeDetectorRef: ChangeDetectorRef, private igLayoutService: IgLayoutService,
        private principalService: PrincipalService) {
        this.mobileQuery = this.igLayoutService.getMediaQueryForMobile();
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
        this.principalService.getAuthenticationState().pipe(delay(0), takeUntil(componentDestroyed(this))).subscribe((account) => {
            if (account) {
                this.isAuthenticated = true;
            } else {
                this.isAuthenticated = false;
            }
        });
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }

    ngOnInit(): void {
        this.igLayoutService.setSideNav(this.sideNav);
        this.principalService
            .getAuthenticationState()
            .pipe(
                delay(0),
                takeUntil(componentDestroyed(this))
            )
            .subscribe(account => {
                this.loggedInUser = account;
                this.checkDisabled();
            });
    }

    checkDisabled() {
        if (this.loggedInUser) {
            this.menuItems.forEach((element) => {
                // check if roles are exist, if not its global
                if (!element.roles && !element.children) {
                    // by default make it as visible
                    element.disabled = false;
                }
                // check for roles if roles are there then go accordingly
                if (element.roles && element.roles.length > 0) {
                    element.roles.some(r => this.loggedInUser.authorities.indexOf(r) >= 0) ?
                        element.disabled = false : element.disabled = true;
                }
                // check for children 
                if (element.children ? element.children.length > 0 : false) {
                    // by default make it as disabled
                    element.disabled = true;
                    // loop to each childrens
                    element.children.forEach((eachChildern) => {
                        if (eachChildern.roles) {
                            // check user authorities
                            if (eachChildern.roles.some(eachRole => this.loggedInUser.authorities.indexOf(eachRole) >= 0)) {
                                eachChildern.disabled = false;
                                element.disabled = false;
                            } else {
                                eachChildern.disabled = true;
                            }
                        }
                    });
                }
            });
        }
    }
}