<mat-card>
    <mat-card-header class="page-title">
        <div mat-card-avatar class="button-icon">
            <button mat-raised-button (click)="goToQuestionaireListPage()">
                <mat-icon>keyboard_backspace</mat-icon>
            </button>
        </div>
        <mat-card-title *ngIf="isSave" class="button-icon-text">Create Questionaire</mat-card-title>
        <mat-card-title *ngIf="isPresent" class="button-icon-text">{{ editOrView }} Questionaire</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="questionaireEditorForm" novalidate (ngSubmit)="onSubmit()">
            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start" class="two-per-row">
                <input name="id" type="hidden" formControlName="id" />
                <mat-form-field>
                    <input name="name" matInput type="text" formControlName="name" placeholder="Name" [attr.tabindex]="0" />
                    <mat-error align="start" *ngIf="formErrors.name">{{ formErrors.name }}</mat-error>
                </mat-form-field>
                &nbsp;
                <mat-form-field>
                    <input name="description" matInput type="text" formControlName="description" placeholder="Description" />
                    <mat-error align="start" *ngIf="formErrors.description">{{ formErrors.description }}</mat-error>
                </mat-form-field>
                &nbsp;
                <mat-form-field>
                    <input name="questionnaireVersionName" matInput type="text" formControlName="questionnaireVersionName" placeholder="Version Name"
                    />
                    <mat-error align="start" *ngIf="formErrors.questionnaireVersionName">{{ formErrors.questionnaireVersionName }}</mat-error>
                </mat-form-field>
                &nbsp;
                <mat-form-field>
                    <input name="questionnaireVersionCode" matInput type="text" formControlName="questionnaireVersionCode" placeholder="Version Code"
                    />
                    <mat-error align="start" *ngIf="formErrors.questionnaireVersionCode">{{ formErrors.questionnaireVersionCode }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <input name="code" matInput type="text" formControlName="code" placeholder="code" />
                    <mat-error align="start" *ngIf="formErrors.code">{{ formErrors.code }}</mat-error>
                </mat-form-field>
                &nbsp;
                <mat-form-field>
                    <mat-select name="module" formControlName="refernceModuleCode" placeholder="Module">
                        <mat-option>--Select--</mat-option>
                        <mat-option *ngFor="let referenceCode of referenceCodeList" [value]="referenceCode.id">{{referenceCode.code}}</mat-option>
                    </mat-select>
                    <mat-error align="start" *ngIf="formErrors.refernceModuleCode">{{ formErrors.refernceModuleCode }}</mat-error>
                </mat-form-field>
                &nbsp;
                <mat-form-field>
                    <mat-select name="businessCode" formControlName="businessCode" placeholder="Business Code">
                        <mat-option>--Select--</mat-option>
                        <mat-option *ngFor="let businessCode of businessCodeList" [value]="businessCode.id">{{businessCode.code}}</mat-option>
                    </mat-select>
                    <mat-error align="start" *ngIf="formErrors.businessCode">{{ formErrors.businessCode }}</mat-error>
                </mat-form-field>

                <mat-card-actions *ngIf="editable" class="pull-right">
                    <button mat-raised-button color="accent" *ngIf="isPresent" (click)="createQuestion()">
                        <mat-icon>add_circle</mat-icon>
                        Add Questions
                    </button>
                    <button mat-raised-button color="primary">
                        <mat-icon>save</mat-icon>Submit</button>
                    <button mat-raised-button (click)="goToQuestionaireListPage()">
                        <mat-icon>cancel</mat-icon>Cancel</button>
                </mat-card-actions>
            </div>
        </form>
    </mat-card-content>
</mat-card>
<div *ngIf="isPresent">
    <app-questions [questions]="questionaire.questions" [questionaireStatus]="questionaire.status"></app-questions>
</div>