import { DropdownAndMultiselect } from "./dropDown-and-multiselect.model";
import { QuestionLinkMapping } from "./question-link-mapping.model";

export class Question {
   
    constructor(
        public id?: number,
        public version?: number,
        public questionnaireId?: number,
        public category?: string,
        public subCategory?: string,
        public name?: string,
        public code?: string,
        public type?: string,
        public sequence?: number,
        public minMark?: number,
        public midMark?: number,
        public maxMark?: number,
        public minTolerance?: number,
        public midTolerance?: number,
        public maxTolerance?: number,
        public weightage?: number,
        public configuration?: string,
        public required?: boolean,
        public configWeightage?: any,
        public flag?: boolean,
        public isDependent?: boolean,
        public parentQuestionId?: number,
        public parentQuestionOptionValue?: string,
        public isVisible?: boolean,
        public questionLinkMappingList?: QuestionLinkMapping,
    ) {}
}


