import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { FormErrorModel } from "projects/ig-core/src/lib/form/form-error.model";
import { IgFormService } from "projects/ig-core/src/lib/form/form.service";
import { takeUntil } from "rxjs/operators";
import { ReferenceCode } from "src/app/admin/reference-code/reference-code.model";
import { ReferenceCodeService } from "src/app/admin/reference-code/reference-code.service";
import { Questionaire } from "../questionaire/questionaire.model";
import { Question } from "./question.model";
import { QuestionService } from "./question.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { ConfigurationCustom } from "./configuration-custom.model";
import { getInterpolationArgsLength } from "@angular/compiler/src/render3/view/util";

const dropdownExampleString =
  '{"choices":["choice1","choice2","choice3"], \n' +
  '"blackWhiteListingItems":{"blackListedItems":[],"whiteListedItems":[]}}';

const numberTypeExampleString =
  '{"min": "Enter min here","max": "Enter max here", \n' +
  '"blackWhiteListingItems":{"blackListedItems":[],"whiteListedItems":[]}}';

const observationExampleString =
  '{"positiveLabel":"Yes", "negativeLabel":"No", "observationChoice":["choices1","choice2","choice3"], \n' +
  '"blackWhiteListingItems":{"blackListedItems":[],"whiteListedItems":[]}}';

const numberTupleExampleString =
  '{"label1":"entervalue", "label2":"entervalue", \n' +
  '"value1min":"minValueOfLabel1", "value1max": "maxValueOfLabel1", \n ' +
  '"value2min":"minValueOfLabel2", "value2max": "maxValueOfLabel2", \n ' +
  '"tupleRule":"value1 lteq value2", \n ' +
  '"blackWhiteListingItems":{"blackListedItems":[],"whiteListedItems":[]}}';

const linkedObservationString =
  '{"linkItems":[["choices1" , "choices2", "choices3", "others"],' +
  '{"choices1":["choices11", "choice12", "choice13", "others"],' +
  '"choices2": ["choice21", "choice22", "choice23", "others"],' +
  '"choices3": ["choice31", "choice32", "choice33", "others"]},' +
  '{"others":"TEXTBOX","choice33":"NUMBERBOX"}], \n' +
  '"blackWhiteListingItems":{"blackListedItems":[],"whiteListedItems":[]}}';
  
const calendarExampleString =
  '{"isMultiDatedEntry":Boolean_Value, \n' +
  '"forwardDatedTo":No_Of_Days,"backDatedTo":No_Of_Days, \n' +
  '"blackWhiteListingItems":{"blackListedItems":[],"whiteListedItems":[]}}';

// const textExampleString = '{"hint":"Remarks", \n'
//     + '"blackWhiteListingItems":{"blackListedItems":[],"whiteListedItems":[]}}';
const textExampleString =
  '{"hint":"Remarks", "metaData": {"isActive":true, ' +
  '"configItems": {"config": "NAME", "limit": "NUMBER", "hint": "Enter *", "inputType": "1/2/3"}}, \n' +
  '"blackWhiteListingItems":{"blackListedItems":[],"whiteListedItems":[]}}';

const MultiMediaExampleString =
  '{"imageType": {"maxUploads": "2", \n' +
  '"maxSizeInKiloBytes": "10000", "isCameraUpload": "false" },  "videoType": {\n' +
  '"maxUploads": "1", "maxDurationInSeconds": "100",\n' +
  '"isCameraUpload" :"true" },  "fileType": {"maxUploads": "2", \n' +
  '"maxSizeInKiloBytes": "10000", "fileType":"pdf"},\n' +
  '"blackWhiteListingItems":{"blackListedItems":[],"whiteListedItems":[]}}';

const LinkedMulitiselectDropdownString =
  '{"linkItems": [["Yes", "No"], \n' +
  '{"No": ["Choice1", "Choice2", "Choice3", "Choice4"]}, \n' +
  '{"Any": "MULTIPICKER CALENDAR"}], "blackWhiteListingItems": {"blackListedItems": [], "whiteListedItems": []}}';

const MultipickerCalendarString =
  '{"isMultiDatedEntry":true,"forwardDatedTo":1,"backDatedTo":30,\n' +
  '"blackWhiteListingItems":{"blackListedItems":[],"whiteListedItems":[""]}}';

const multiSelectdropdownExampleString =
  ' { "minSelect": "Enter min here","maxSelect": "Enter max here", \n' +
  '"choices":["choice1","choice2","choice3"], \n' +
  '"blackWhiteListingItems":{"blackListedItems":[],"whiteListedItems":[]}}';

// -------------------

const numberWaghtageTypeExampleString =
  '{"0-50": "20", "50-100": "25", default: "10"}';

const observationWaghtageExampleString =
  '{"Yes":"15", "No":"25", default: "10"}';

const textWaghtageExampleString = '{"NOT AVAILABLE":"20", default: "10"}';

const dropdownWaghtageExampleString =
  '{"Choice1":"5", "Choice2":"10", "Choice3":"15", default: "10"}';

const MultiMediaWaghtageExampleString =
  '{"imageType":"30", "videoType":"40", "fileType":"50", default: "10"}';

const calendarWaghtageExampleString =
  '{"CURRENTDATE":"20", "dd-MM-yyyy":"25", \n' +
  '"dd-MM-yyyy/dd-MM-yyyy":"25", "default":"10"}';

const multiSelectWaghtagedropdownExampleString =
  '{"Choice1":"5", "Choice2":"10", "Choice3":"15", default: "10"}';

@Component({
  selector: "app-question-creation",
  templateUrl: "./question-editor.component.html",
})
export class QuestionEditorComponent implements OnInit, OnDestroy {
  @ViewChild("myDialog", { static: false }) myDialog: TemplateRef<any>;

  question: Question;
  questionaire: Questionaire;
  editable = true;
  isSave = true;
  isPresent: boolean;
  isAvaible: boolean;
  required = true;
  configExample: string;
  configWaghtageExample: string;
  editOrView: string;
  configJsonError: any;
  configWeightageJsonError: any;
  sequenceError: any;
  public questionEditorForm: FormGroup;
  public formError: FormErrorModel;
  questionsSequenceList: number[] = [];
  actualQuestionSequnce: number;
  weightage: boolean;
  multiChoiceCount: number = 1;
  multiDropMinValue: number = 1;
  multiDropMaxValue: number = 1;
  isDependentToggle: boolean = false; 
  public formErrors = {
    subCategory: "",
    category: "",
    type: "",
    code: "",
    name: "",
    sequence: "",
    midMark: "",
    maxMark: "",
    weightage: "",
    isRequired: "",
    minTolerance: "",
    midTolerance: "",
    maxTolerance: "",
    questionnaireVersionName: "",
    questionnaireVersionCode: "",
  };
  referenceCodes: ReferenceCode[];

  questionTypes = [
    { value: "NUMBER TUPLE" },
    { value: "OBSERVATION" },
    { value: "NUMBER" },
    { value: "TEXT" },
    { value: "DROPDOWN" },
    { value: "LINKED DROPDOWN" },
    { value: "CALENDAR" },
    { value: "MULTIMEDIA" },
    { value: "LINKED MULTISELECT DROPDOWN" },
    { value: "MULTIPICKER CALENDAR" },
    { value: "MULTISELECT DROPDOWN" },
  ];
  parentQuestionTypes = [];
  parentOptionValues;
  questionLinkMapping;

  configWeightage: any = {};
  isDependent: boolean = false;
  selectedParentQuestionType: any;
  isParentQuestion: boolean;

  constructor(
    private questionService: QuestionService,
    private referenceCodeService: ReferenceCodeService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private igFormService: IgFormService,
    private dialog: MatDialog
  ) {
    this.activatedRoute.data
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        console.log("data:", data);
        if (data.question === undefined) {
          this.isPresent = true;
          this.question = {};
        } else {
          this.editable = data["editable"];
          this.isAvaible = true;
          this.question = data.question;

          this.isDependent = this.question.isDependent;
          this.setEConfig(this.question.type);
          this.setEConfigWeightage(this.question.type);
          this.required = this.question.required;
        }
        this.editable = data["editable"];
      });

    this.activatedRoute.params
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((params) => {
        this.question.questionnaireId = params.questionaireId;
      });
    this.referenceCodeService
      .getLatestCategoryWeightageMapping()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((success) => {
        this.referenceCodes = success;
      });
    this.questionService.mQuestionsSequenceList
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((questionSequenceList) => {
        this.questionsSequenceList = questionSequenceList;
      });
  }

  setEConfig(element) {
    if (element === undefined) {
      return;
    }
    if (element === "OBSERVATION") {
      this.configExample = observationExampleString;
    } else if (element === "NUMBER") {
      this.configExample = numberTypeExampleString;
    } else if (element === "NUMBER TUPLE") {
      this.configExample = numberTupleExampleString;
    } else if (element === "DROPDOWN") {
      this.configExample = dropdownExampleString;
    } else if (element === "TEXT") {
      this.configExample = textExampleString;
    } else if (element === "LINKED DROPDOWN") {
      this.configExample = linkedObservationString;
    } else if (element === "CALENDAR") {
      this.configExample = calendarExampleString;
    } else if (element === "MULTIMEDIA") {
      this.configExample = MultiMediaExampleString;
    } else if (element === "LINKED MULTISELECT DROPDOWN") {
      this.configExample = LinkedMulitiselectDropdownString;
    } else if (element === "MULTIPICKER CALENDAR") {
      this.configExample = MultipickerCalendarString;
    } else if (element === "MULTISELECT DROPDOWN") {
      this.configExample = multiSelectdropdownExampleString;
    }
  }

  setEConfigWeightage(element) {
    if (element === undefined) {
      return;
    }
    if (element === "OBSERVATION") {
      this.configWaghtageExample = observationWaghtageExampleString;
    } else if (element === "NUMBER" || element === "NUMBER TUPLE") {
      this.configWaghtageExample = numberWaghtageTypeExampleString;
    } else if (
      element === "DROPDOWN" ||
      element === "LINKED DROPDOWN" ||
      element === "LINKED MULTISELECT DROPDOWN"
    ) {
      this.configWaghtageExample = dropdownWaghtageExampleString;
    } else if (element === "TEXT") {
      this.configWaghtageExample = textWaghtageExampleString;
    } else if (element === "CALENDAR" || element === "MULTIPICKER CALENDAR") {
      this.configWaghtageExample = calendarWaghtageExampleString;
    } else if (element === "MULTIMEDIA") {
      this.configWaghtageExample = MultiMediaWaghtageExampleString;
    } else if (element === "MULTISELECT DROPDOWN") {
      this.configWaghtageExample = multiSelectWaghtagedropdownExampleString;
    }
    this.configWeightage = {};
    this.questionEditorForm.controls["configWeightage"].setValue(
      JSON.stringify(this.configWeightage, undefined, 2)
    );
  }

  ngOnInit() {
    this.formError = new FormErrorModel(false, "");
    this.buildForm();
    this.editOrView = "Edit";
    console.log(this.editable);

    if (!this.editable) {
      this.questionEditorForm.disable();
      this.editOrView = "View";
    }
    console.log(this.question);
    this.loadParentQuestion( this.question?.id, this.question?.questionnaireId);
    this.loadQuestionLinkMapping(this.question?.id);
   
  }

  ngAfterViewInit(){
    this.loadParentQuestionOptions( this.question.questionLinkMappingList[0]?.parentQuestionId);

  }

  ngOnDestroy(): void {}

  buildForm() {
    this.questionEditorForm = this.formBuilder.group({
      id: [this.question.id],
      subCategory: [this.question.subCategory],
      category: [this.question.category, Validators.required],
      type: [this.question.type, Validators.required],
      code: [this.question.code],
      questionnaireId: [this.question.questionnaireId],
      name: [this.question.name, Validators.required],
      sequence: [
        this.question.sequence,
        [Validators.required, Validators.min(1)],
      ],
      version: [this.question.version],
      minMark: [this.question.minMark],
      midMark: [this.question.midMark],
      maxMark: [this.question.maxMark],
      weightage: [this.question.weightage],
      minTolerance: [this.question.minTolerance],
      midTolerance: [this.question.midTolerance],
      maxTolerance: [this.question.maxTolerance],
      econfiguration: "",
      econfigWeightage: "",
      configuration: [this.question.configuration],
      configWeightage: [this.question.configWeightage],
      required: [ (this.question.required !== undefined && this.question.required != null ) ? this.question.required : true],
      isDependent: [this.question.isDependent],
      parentQuestionId: this.getParentQuestionId(this.question),
      parentQuestionOptionValue: this.getParentOPtionValues(this.question),
      isVisible: [],
    });

    this.questionEditorForm.valueChanges
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        this.formErrors = this.igFormService.validateForm(
          this.questionEditorForm,
          this.formErrors,
          true
        );
      });
    this.actualQuestionSequnce = this.question.sequence;
  }

  getParentQuestionId(question) {
    if (!question.isDependent) {
      return null;
    }
    console.log('testing for parent Id  '+this.question.questionLinkMappingList[0]?.parentQuestionId)
    return [
      this.question.questionLinkMappingList[0]?.parentQuestionId
        ? this.question.questionLinkMappingList[0]?.parentQuestionId
        : null,
    ];
  
  }

  getParentOPtionValues(question) {
    if (!question.isDependent) {
      return null;
    }
    console.log('testing for option value  '+this.question.questionLinkMappingList[0]?.parentQuestionOptionValue)
    if(this.question.questionLinkMappingList[0]?.parentQuestionId){
     question.parentQuestionOptionValue =  [ this.question.questionLinkMappingList[0]?.parentQuestionOptionValue
     ? this.question.questionLinkMappingList[0]?.parentQuestionOptionValue  : null];
    return  question.parentQuestionOptionValue[0];
    }else{
      return null;
    }
  }

  onSubmit() {
    this.formError = new FormErrorModel(false, "");
    this.configJsonError = "";
    this.configWeightageJsonError = "";
    this.sequenceError = "";
    this.igFormService.markFormGroupTouched(this.questionEditorForm);

    // const parentQuestionOptionValue = this.questionEditorForm.controls["parentQuestionOptionValue"].value;
    // const dropdownAndMultiselectDTO = this.parentOptionValues;
    // console.log("dropdownAndMultiselectDTO:", dropdownAndMultiselectDTO);
    // if((dropdownAndMultiselectDTO.length==0 || dropdownAndMultiselectDTO.length ==undefined) && parentQuestionOptionValue)
    // {     
    // this.questionEditorForm.controls["parentQuestionOptionValue"].setValue(parentQuestionOptionValue.toLowerCase());
    // }

    if (this.questionEditorForm.get('isDependent').value) {
      const parentQuestionId = this.questionEditorForm.controls['parentQuestionId'].value;
      const parentQuestionOptionValue = this.questionEditorForm.controls['parentQuestionOptionValue'].value;
  
      if (!parentQuestionId || parentQuestionId.length === 0 || !parentQuestionOptionValue) {
        this.snackBar.open('Parent question name and option value are mandatory for dependent questions.', 'Close', { 
          duration: 5000,
        });
        return false;
      }
    }
  
    if (this.questionEditorForm.valid) {
      if (!this.isSequenceValid()) {
        return false;
      }
      try {
        JSON.parse(this.questionEditorForm.controls["configuration"].value);
        if (this.questionEditorForm.controls["configWeightage"].value) {
          const data = JSON.parse(
            this.questionEditorForm.controls["configWeightage"].value
          );
          const maxMark = this.getTotalWeightage(data);
          if (maxMark === 100) {
            this.questionEditorForm.controls["maxMark"].setValue(maxMark);
          } else {
            this.configWeightageJsonError =
              "Invalid Weightage Config Max Mark always 100";
            return false;
          }

          // Validation for multiselect drop-down
          const typeValue = this.questionEditorForm.controls["type"].value;
          let isValid = true; // Initialize a flag to track validation status

          switch (typeValue) {
            case "MULTISELECT DROPDOWN":
              // min and max values not allowing decimals
              var minResult =
                this.multiDropMinValue - Math.floor(this.multiDropMinValue) !==
                0;
              var maxResult =
                this.multiDropMaxValue - Math.floor(this.multiDropMaxValue) !==
                0;

              if (minResult && maxResult) {
                this.configJsonError = "Both numbers have decimal value.";
                isValid = false;
              } else if (minResult) {
                this.configJsonError =
                  "Minimum number " +
                  this.multiDropMinValue +
                  " has a decimal value.";
                isValid = false;
              } else if (maxResult) {
                this.configJsonError =
                  "Maximum number " +
                  this.multiDropMaxValue +
                  " has a decimal value.";
                isValid = false;
              }

              if (
                this.multiDropMinValue == undefined &&
                this.multiDropMaxValue == undefined
              ) {
                this.configJsonError =
                  "Minimum and Maximum value should be present";
                isValid = false;
              } else if (
                this.multiDropMinValue == undefined ||
                this.multiDropMaxValue == undefined
              ) {
                this.configJsonError =
                  "Minimum or Maximum values should be given at least 1";
                isValid = false;
              } else if (
                this.multiDropMinValue == 0 &&
                this.multiDropMaxValue == 0
              ) {
                this.configJsonError =
                  "Minimum and Maximum value should not be given 0";
                isValid = false;
              } else if (
                this.multiDropMinValue == 0 ||
                this.multiDropMaxValue == 0
              ) {
                this.configJsonError =
                  "Minimum or Maximum values should not be given 0";
                isValid = false;
              } else if (
                String(this.multiDropMinValue).trim() === "" ||
                String(this.multiDropMaxValue).trim() === ""
              ) {
                this.configJsonError =
                  "selected minimum or maximum value should not be empty";
                isValid = false;
              } else if (
                this.multiDropMinValue < 0 ||
                this.multiDropMaxValue < 0
              ) {
                this.configJsonError =
                  "Minimum or Maximum value should not be less than 0";
                isValid = false;
              } else if (this.multiDropMinValue > this.multiChoiceCount) {
                if (
                  this.multiDropMinValue -
                    Math.floor(this.multiDropMinValue) !==
                  0
                ) {
                  this.configJsonError =
                    "Selected Minimum value " +
                    this.multiDropMinValue +
                    " should not be a decimal value and should be less than or equal to the number of choices (" +
                    this.multiChoiceCount +
                    ").";
                } else {
                  this.configJsonError =
                    "Selected Minimum value " +
                    this.multiDropMinValue +
                    " should be less than or equal to the number of choices (" +
                    this.multiChoiceCount +
                    ").";
                }
                isValid = false;
              } else if (this.multiDropMinValue > this.multiDropMaxValue) {
                this.configJsonError =
                  "Selected Minimum value " +
                  this.multiDropMinValue +
                  " should be less than and equal to maximum value " +
                  this.multiDropMaxValue;
                isValid = false;
              }
              break;
            default:
              break;
          }
          if (!isValid) {
            return false; // Return false if any validation failed
          }
        }
      } catch (error) {
        this.configJsonError = "Invalid JSON Format : " + error.message;
        this.configWeightageJsonError =
          "Invalid JSON Format : " + error.message;
        return false;
      }
      this.questionService
        .saveQuestion(this.questionEditorForm.value)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(
          (response) => this.onSubmitSuccess(response),
          (response) => this.onSubmitError(response)
        );
    } else {
      this.formErrors = this.igFormService.validateForm(
        this.questionEditorForm,
        this.formErrors,
        false
      );
      return false;

    }
  }
  /**
   * This method is to check if the question sequence is valid or not
   */
  checkIfSequenceIsValid() {
    this.sequenceError = "";
    if (!this.isSequenceValid()) {
      this.sequenceError = "A question with same sequence already exists!";
    }
  }
  /**
   *
   * @description This method is to validate if the sequence are correct
   */
  isSequenceValid() {
    let sequenceError = true;
    const currentSequence: number =
      this.questionEditorForm.controls["sequence"].value;
    if (
      this.actualQuestionSequnce !== currentSequence &&
      this.questionsSequenceList.includes(currentSequence)
    ) {
      sequenceError = false;
    }
    return sequenceError;
  }

  onSubmitSave(element: any) {
    this.isSave = element;
    if (this.isSave) {
      this.onSubmit();
    } else {
      this.onSubmit();
    }
  }

  private onSubmitSuccess(response) {
    this.snackBar.open(
      `Updated question ${response.body.name} successfully`,
      "Close",
      {
        duration: 3000,
      }
    );
    if (this.isSave) {
      this.goToQuestionList();
    } else {
      this.resetPage();
    }
  }

  private onSubmitError(response) {
    const errorModel = this.igFormService.handleServerError(
      this.questionEditorForm,
      this.formErrors,
      response.error
    );
    if (errorModel && errorModel.isValidationError) {
      this.formErrors = errorModel.formErrors;
    }
  }
  resetPage() {
    this.question.configuration = "";
    this.question.configWeightage = "";
    this.isDependent = false;
    this.ngOnInit();
  }

  goToQuestionList() {
    this.router.navigate(["questionaire/edit", this.question.questionnaireId]);
  }

  changeValue(element: any) {
    this.setEConfig(element.value);
    this.setEConfigWeightage(element.value);
  }

  trackByFn(item) {
    return item;
  }

  onClickWaghtage(templateRef: TemplateRef<any>) {
    const element = this.questionEditorForm.controls["type"].value;
    let configData = {};
    try {
      configData = JSON.parse(
        this.questionEditorForm.controls["configuration"].value
      );
      if (!this.questionEditorForm.controls["configWeightage"].value) {
        this.configWeightage = {};
      }
    } catch (error) {
      this.snackBar.open(
        "Error : " + error + " Please Check and Update",
        "Close",
        {
          duration: 3000,
        }
      );
      return false;
    }
    if (element === "OBSERVATION") {
      this.setJsonValue(configData["positiveLabel"]);
      this.setJsonValue(configData["negativeLabel"]);
      /* configData["observationChoices"].forEach(choise => {
                this.configJson[choise] = this.getJsonValue(choise);
            });*/
    } else if (element === "NUMBER TUPLE") {
      this.setJsonValue("0");
      this.setJsonValue("1-20");
      this.setJsonValue("21-40");
      this.setJsonValue("41-60");
      this.setJsonValue("61-80");
      this.setJsonValue("81-99");
      this.setJsonValue("100");
    } else if (element === "NUMBER") {
      this.setJsonValue(configData["min"]);
      this.setJsonValue(configData["max"]);
      if (Number(configData["max"]) - Number(configData["min"]) >= 2) {
        const range1 = Number(configData["min"]) + 1;
        const range2 = Number(configData["max"]) - 1;
        this.setJsonValue(range1 + "-" + range2);
      } else {
        const range1 = Number(configData["min"]);
        const range2 = Number(configData["max"]);
        this.setJsonValue(range1 + "-" + range2);
      }
    } else if (element === "DROPDOWN") {
      configData["choices"].forEach((choise) => {
        this.setJsonValue(choise);
      });
    } else if (element === "TEXT") {
      this.setJsonValue("NOT AVAILABLE");
      this.setJsonValue("default");
    } else if (element === "CALENDAR" || element === "MULTIPICKER CALENDAR") {
      this.setJsonValue("CURRENTDATE");
      this.setJsonValue("default");
    } else if (element === "MULTIMEDIA") {
      this.setJsonValue("imageType");
      this.setJsonValue("videoType");
      this.setJsonValue("fileType");
    } else if (
      element === "LINKED DROPDOWN" ||
      element === "LINKED MULTISELECT DROPDOWN"
    ) {
      configData["linkItems"].forEach((choise) => {
        if (typeof choise !== "undefined" ? Array.isArray(choise) : false) {
          var nonDeleteItem = choise;
          choise.forEach((eachChoise) => {
            configData["linkItems"].forEach((eachObject) => {
              if (
                typeof eachObject !== "undefined"
                  ? !Array.isArray(eachObject)
                  : false
              ) {
                if (eachObject.hasOwnProperty(eachChoise)) {
                  nonDeleteItem = nonDeleteItem.filter(
                    (item) => item !== eachChoise
                  );
                  if (typeof eachObject[eachChoise] !== "string") {
                    eachObject[eachChoise].forEach((eachValue) => {
                      this.setJsonValue(eachValue);
                    });
                  }
                }
              }
            });
          });
          nonDeleteItem.forEach((eachValue) => this.setJsonValue(eachValue));
        }
      });
    } else if (element === "MULTISELECT DROPDOWN") {
      this.multiDropMinValue = configData["minSelect"];
      this.multiDropMaxValue = configData["maxSelect"];
      this.multiChoiceCount = 0;
      configData["choices"].forEach((choise) => {
        this.setJsonValue(choise);
        this.multiChoiceCount = this.multiChoiceCount + 1;
      });
    }
    this.questionEditorForm.controls["configWeightage"].setValue(
      JSON.stringify(this.configWeightage, undefined, 2)
    );
    this.dialog.open(this.myDialog, { width: "70%", height: "auto" });
    this.dialog.afterAllClosed
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((result) => {
        this.questionEditorForm.controls["configWeightage"].setValue(
          JSON.stringify(this.configWeightage, undefined, 2)
        );
      });
  }

  setJsonValue(key: any) {
    this.configWeightage["" + key] = this.configWeightage.hasOwnProperty(key)
      ? this.configWeightage[key]
      : 0;
  }

  getTotalWeightage(data: any) {
    var max = 0;
    if (
      this.questionEditorForm.controls["type"].value ===
      "LINKED MULTISELECT DROPDOWN"
    ) {
      Object.keys(data).forEach(function (key) {
        max += parseInt(data[key]);
      });
    } else {
      Object.keys(data).forEach(function (key) {
        if (max <= parseInt(data[key])) max = parseInt(data[key]);
      });
    }
    return max;
  }

  changeWeightage(element: any) {
    this.question.subCategory = element.value;
    this.questionEditorForm.controls["subCategory"].setValue(
      this.question.subCategory
    );
    for (const entry of this.referenceCodes) {
      if (element.value === entry.name) {
        this.question.weightage = parseFloat(entry.code);
        this.questionEditorForm.controls["weightage"].setValue(
          this.question.weightage
        );
        break;
      }
    }
  }

  onToggle() {
    this.isDependent = !this.isDependent;
      if (this.questionEditorForm.get('isDependent').value) {
      // Reset values and hide fields if not dependent
      this.questionEditorForm.patchValue({
        parentQuestionId: "",
        parentQuestionOptionValue: "",
      });
      this.parentOptionValues.dropdownAndMultiselectDTO.choices=[]
    }
  }

  //to get the parent question names
  loadParentQuestion(id: number, questionaireId: number): void {
    this.questionService
      .getParentQuestion(id, questionaireId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (response) => {
          console.log("Parent Question Names:", response.body);
          this.parentQuestionTypes = response.body;
         
          const selectedQuestionId =
            response.body.length > 0 ? response.body[0].id : null;
          if (selectedQuestionId !== undefined && selectedQuestionId !== null) {
              this.isDependentToggle = true;

            // this.loadParentQuestionOptions(selectedQuestionId);
          }
          else
          {
            this.isDependentToggle = false;
          }
        },
        (error) => {
          console.error("Error fetching parent question names:", error);
        }
      );
  }

  onParentQuestionNameChange(event): void {
    this.loadParentQuestionOptions(event.value);
  }

  //to get the parent question option values
  loadParentQuestionOptions(questionId: number): void {
    this.questionService
      .getParentQuestionOptions(questionId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (response) => {
          // console.log("Parent Question values:", response);
          this.parentOptionValues = response;
        },
        (error) => {
          console.error("Error fetching parent question options:", error);
        }
      );
  }
  loadQuestionLinkMapping(parentQuestionId: number): void {
    this.questionService.getQuestionLinkMapping(parentQuestionId)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(
            (response) => {
                console.log("questionLinkMapping", response);
                this.questionLinkMapping = response;

                this.isParentQuestion = false; // Set to false to enable the configuration
                if ( response.length > 0 ) {
                    console.log("isParentQuestion", this.isParentQuestion);
                    this.isParentQuestion = true; // Set to true if question is a parent question
                }
            },
        );
}

}
