import { Question } from '../question/question.model';

export class Questionaire {
    constructor(
        public id?: number,
        public version?: number,
        public name?: string,
        public active?: boolean,
        public code?: string,
        public description?: string,
        public questionnaireVersionName?: string,
        public questionnaireVersionCode?: string,
        public questionaireVersionCode?: string,
        public questions?: Question[],
        public status?: string,
        public refernceModuleCode?: number,
        public businessCode?: number,
    ) {}
}

