import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ChartsModule } from 'ng2-charts';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { MaterialLibModule } from '../material-lib.module';
import { AdminRoutingModule } from './admin-routing.module';
import { AuthorityModule } from './authority/authority.module';
import { RoleModule } from './role/role.module';
import { UserModule } from './user/user.module';
import { BranchModule } from './branch/branch.module';
import { BranchSetModule } from './branch-set/branch-set.module';
import { ReferenceCodeModule } from './reference-code/reference-code.module';
import { CenterModule } from './center/center.module';
import { AppDataModule } from './app-data/app-data.module';
import { ApplicationUsageStatsModule } from './application-usage-stats/application-usage-stats.module';
import { QuestionnaireConfigurationModule } from './questionnaire-configuration/questionnaire-configuration.module'
import { MasterFilterUploadModule } from './questionnaire-configuration/master-filter-upload/master-filter-upload.module';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    LayoutModule,
    MaterialLibModule,
    NgxJsonViewerModule, /*https://github.com/hivivo/ngx-json-viewer#readme*/
    FlexLayoutModule,
    ChartsModule,
    HttpClientModule,
    UserModule,
    RoleModule,
    AuthorityModule,
    AdminRoutingModule,
    BranchModule,
    BranchSetModule,
    CenterModule,
    AppDataModule,
    ApplicationUsageStatsModule,
    ReferenceCodeModule,
    QuestionnaireConfigurationModule,
    MasterFilterUploadModule

  ],
  declarations: [],
  exports: [],
  entryComponents: [
  ],
  providers: []
})
export class AdminModule { }
