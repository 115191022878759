<mat-card>
    <mat-card-header class="page-title">
        <div mat-card-avatar class="button-icon">
            <button mat-raised-button (click)="goToQuestionList()">
                <mat-icon>keyboard_backspace</mat-icon>
            </button>
        </div>
        <mat-card-title *ngIf="isPresent" class="button-icon-text">Create Question</mat-card-title>
        <mat-card-title *ngIf="isAvaible" class="button-icon-text">{{ editOrView }} Question</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="questionEditorForm">
            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start"
                class="two-per-row">
                <input name="id" type="hidden" formControlName="id" />

                <mat-form-field>
                    <input name="name" matInput type="text" formControlName="name" placeholder="Question Name"
                        [attr.tabindex]="0" />
                    <mat-error align="start" *ngIf="formErrors.name">{{ formErrors.name }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-select name="category" formControlName="category" placeholder="Category"
                        (selectionChange)="changeWeightage($event)">
                        <mat-option>--Select--</mat-option>
                        <mat-option *ngFor="let category of referenceCodes" [value]="category.name">
                            {{category.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error align="start" *ngIf="formErrors.category">{{ formErrors.category }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <input name="subCategory" readonly matInput type="text" formControlName="subCategory"
                        placeholder="SubCategory" />
                    <mat-error align="start" *ngIf="formErrors.subCategory">{{ formErrors.subCategory }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <input name="weightage" readonly min="1" matInput type="number" formControlName="weightage"
                        placeholder="Weightage" />
                    <mat-error align="start" *ngIf="formErrors.weightage">{{ formErrors.weightage }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <input name="minMark" min="1" matInput type="number" formControlName="minMark"
                        placeholder="Min Mark" />
                    <mat-error align="start" *ngIf="formErrors.midMark">{{ formErrors.minMark }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <input name="midMark" min="1" matInput type="number" formControlName="midMark"
                        placeholder="Mid Mark" />
                    <mat-error align="start" *ngIf="formErrors.midMark">{{ formErrors.midMark }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <input name="maxMark" min="1" matInput type="number" formControlName="maxMark"
                        placeholder="Max Mark" />
                    <mat-error align="start" *ngIf="formErrors.maxMark">{{ formErrors.maxMark }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <input name="minTolerance" matInput type="number" formControlName="minTolerance"
                        placeholder="MinTolerance" />
                    <mat-error align="start" *ngIf="formErrors.maxTolerance">{{ formErrors.minTolerance }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <input name="midTolerance" matInput type="number" formControlName="midTolerance"
                        placeholder="MidTolerance" />
                    <mat-error align="start" *ngIf="formErrors.midTolerance">{{ formErrors.midTolerance }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <input name="maxTolerance" matInput type="number" formControlName="maxTolerance"
                        placeholder="MaxTolerance" />
                    <mat-error align="start" *ngIf="formErrors.maxTolerance">{{ formErrors.maxTolerance }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-select name="type" formControlName="type" placeholder="Type"
                        (selectionChange)="changeValue($event)">
                        <mat-option>--Select--</mat-option>
                        <mat-option *ngFor="let type of questionTypes" [value]="type.value">{{type.value}}</mat-option>
                    </mat-select>
                    <mat-error align="start" *ngIf="formErrors.type">{{ formErrors.type }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <input name="sequence" min="1" matInput type="number" formControlName="sequence"
                        placeholder="Sequence" (input)="checkIfSequenceIsValid()" />
                    <mat-error align="start" *ngIf="formErrors.sequence">{{ formErrors.sequence }}</mat-error>
                    <small align="start" style="color: red" *ngIf="sequenceError!=''">{{sequenceError}}</small>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Configuration</mat-label>
                    <textarea name="configuration" align="start" matInput formControlName="configuration"  rows="10"
                        cols="50" [(ngModel)]="question.configuration"  [attr.disabled]="!isParentQuestion " >
                     </textarea>
                    <small align="start" style="color: red" *ngIf="configJsonError!=''">{{configJsonError}}</small>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Example Of Configuration:</mat-label>
                    <textarea readonly align="start" matInput formControlName="econfiguration" rows="10" cols="50"
                        [(ngModel)]="configExample"></textarea>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Configuration Weightage</mat-label>
                    <textarea name="configWeightage" align="start" matInput formControlName="configWeightage" rows="10"
                        cols="50" [(ngModel)]="question.configWeightage"> </textarea>
                    <small align="start" style="color: red"
                        *ngIf="configWeightageJsonError!=''">{{configWeightageJsonError}}</small>
                    <mat-icon matSuffix style="margin-right: 0px !important; vertical-align: middle;"
                        (click)="onClickWaghtage(myDialog)">chrome_reader_mode
                    </mat-icon>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Example Of Configuration Weightage:</mat-label>
                    <textarea readonly align="start" matInput formControlName="econfigWeightage" rows="10" cols="50"
                        [(ngModel)]="configWaghtageExample"></textarea>
                </mat-form-field>

                <div class="mat-form-field">
                    <mat-slide-toggle name="required" formControlName="required">Mandatory</mat-slide-toggle>
                </div>

               <div class="mat-form-field" *ngIf="isDependentToggle">
                      <mat-slide-toggle (change)="onToggle()" name="isDependent" formControlName="isDependent" >is Dependent</mat-slide-toggle>
                </div>


        <ng-container *ngIf="isDependent">
            <mat-card>
              <mat-card-content>             
                <div>
                  <mat-form-field style="width:470px;">
                    <mat-select name="parentQuestionName" formControlName="parentQuestionId" placeholder="Parent Question Name" (selectionChange)="onParentQuestionNameChange($event)">
                        <mat-option>--Select--</mat-option>
                      <mat-option *ngFor="let parentQuestions of parentQuestionTypes" [value]="parentQuestions.id">
                        {{parentQuestions.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  &nbsp;&nbsp;&nbsp;

                  <!-- <ng-container *ngIf="questionEditorForm.value.parentQuestionId"> -->
                  <!-- <mat-form-field style="width: 470px;" *ngIf="parentOptionValues==undefined || parentOptionValues.dropdownAndMultiselectDTO === null">
                    <textarea matInput name="parentQuestionOptionValue" formControlName="parentQuestionOptionValue" placeholder="Parent Question Option Value"></textarea>
                  </mat-form-field> -->
          
                  <mat-form-field style="width: 470px;" *ngIf="parentOptionValues && parentOptionValues.dropdownAndMultiselectDTO !== null && questionEditorForm.get('parentQuestionId').value">
                    <mat-select name="parentQuestionOptionValue" formControlName="parentQuestionOptionValue" placeholder="Parent Question Option Value">
                        <mat-option>--Select--</mat-option>
                      <mat-option *ngFor="let optionValue of parentOptionValues.dropdownAndMultiselectDTO.choices" [value]="optionValue">
                        {{ optionValue }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                <!-- </ng-container> -->
          
                </div>
              </mat-card-content>
            </mat-card>
          </ng-container>
          
              
                <mat-card-actions *ngIf="editable" class="pull-right">
                    <button mat-raised-button color="primary" (click)="onSubmitSave(true)">Save</button>
                    <button mat-raised-button color="accent" *ngIf="isPresent" (click)="onSubmitSave(false)">Save &
                        Continue</button>
                    <button mat-raised-button (click)="goToQuestionList()">Cancel</button>
                </mat-card-actions>
            </div>
        </form>
    </mat-card-content>
</mat-card>

<ng-template #myDialog>
    <h2 matDialogTitle>Auto Generated Configuration from Analysis</h2>
    <mat-dialog-content style="overflow-x: hidden; width: 95%; overflow-y: auto;">
        <p *ngFor='let item of configWeightage | keyvalue; trackBy: trackByFn'>
            <b>{{item.key}}</b> Weightage: <input type="number" [(ngModel)]="configWeightage[item.key]"
                placeholder="Enter Value" />
        </p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose color="primary">Dismiss</button>
    </mat-dialog-actions>
</ng-template>