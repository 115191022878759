<mat-card>
  <mat-card-header class="page-title">
      <div mat-card-avatar class="button-icon">
          <button mat-raised-button (click)="goToQuestionnaireConfigurationListPage()">
              <mat-icon>keyboard_backspace</mat-icon>
          </button>
      </div>
      <mat-card-title *ngIf="!editable" class="button-icon-text">View  Questionnaire Configuration</mat-card-title>
      <mat-card-title *ngIf="editable && isNew" class="button-icon-text">Create Questionnaire Configuration</mat-card-title>
      <mat-card-title *ngIf="editable && !isNew" class="button-icon-text">Edit Questionnaire Configuration</mat-card-title>
  </mat-card-header>
  
  <mat-card-content>
      <form [formGroup]="questionnaireConfigurationEditorForm" novalidate (ngSubmit)="onSubmit()">
                   <div fxLayout="row wrap"fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start">
              
                  
                      <input name="id" type="hidden" formControlName="id" />
                      <input name="version" type="hidden" formControlName="version" />
                      
                      <mat-form-field >
                          <mat-select placeholder="Business Line"  formControlName="businessLineId" 
>
                            <mat-option *ngFor="let businessLine of businessLines" [value]="businessLine.id">
                              {{businessLine.name}} 
                            </mat-option>
                          </mat-select>
                          <mat-error align="start">  {{ formErrors.businessLineId }}</mat-error>
                        </mat-form-field>
                        &nbsp;&nbsp;

                        <mat-form-field >
                          <mat-select placeholder="Questionnaire Code"  formControlName="questionnaireCode">
                            <mat-option *ngFor="let questionnaireCode of questionnaires" [value]="questionnaireCode.code">
                              {{questionnaireCode.code}} 
                            </mat-option>
                          </mat-select>
                          <mat-error align="start">  {{ formErrors.questionnaireCode }}</mat-error>
                        </mat-form-field>
                        &nbsp;&nbsp;
                  </div>
                      
                  <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start">
                      <mat-card-header class="page-title">
                          <mat-card-title class="button-icon-text">
                              Add Processor
                          </mat-card-title>
                      </mat-card-header>
                  </div>
              
                  <div fxLayout="row wrap"  fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start">
                      <mat-form-field >
                          <mat-select placeholder="Android Process" formControlName="android">
                            <mat-option *ngFor="let templateAndroid of templateMasterAndroids" [value]="templateAndroid">
                              {{templateAndroid.displayName}} 
                            </mat-option>
                          </mat-select>
                          <mat-error align="start">  {{ formErrors.android }}</mat-error>
                        </mat-form-field>
                        &nbsp;&nbsp;

                        <mat-form-field >
                          <mat-select placeholder="Java Process"  formControlName="java">
                            <mat-option *ngFor="let templateJava of templateMasterJavas" [value]="templateJava">
                              {{templateJava.displayName}} 
                            </mat-option>
                          </mat-select>
                          <mat-error align="start">  {{ formErrors.java }}</mat-error>
                        </mat-form-field>
                  </div>

                  <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start">
                      <mat-card-header class="page-title">
                          <mat-card-title class="button-icon-text">
                              Optional
                          </mat-card-title>
                      </mat-card-header>
                  </div>
                  <div fxLayout="row wrap"fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start" class="two-per-row" >
              
              
                  <mat-checkbox   class="pull-right" color="primary"  formControlName="isActivityReport"> 
                   Activity Report</mat-checkbox>
           
                        &nbsp;&nbsp;

                      <mat-checkbox    class="pull-right" color="primary" formControlName="isAuditTransactional"> 
                       Activity Audit</mat-checkbox>
                       &nbsp;&nbsp;
                       <mat-checkbox class="pull-right" color="primary" formControlName="isFrontEndUpload">Front End Upload</mat-checkbox>
                       &nbsp;&nbsp;
                       <mat-checkbox class="pull-right" color="primary" formControlName="isProductTypeEnable">
                        Product Type Enable</mat-checkbox>
                        &nbsp;&nbsp;
                       <mat-checkbox class="pull-right" color="primary" formControlName="isGpsTracking">
                        GPS Tracking  </mat-checkbox>

                        &nbsp;&nbsp;
                        <mat-form-field>
                          <input name="gpsTrackingRadius" matInput type="text"
                                 formControlName="gpsTrackingRadius" placeholder="GPS Tracking Radius" />
                      </mat-form-field>
                  
              
              <mat-card-actions *ngIf="editable" class="pull-right" >
                  <button mat-raised-button color="primary">Submit</button>
                  <button mat-raised-button (click)="goToQuestionnaireConfigurationListPage()">Cancel</button>
              </mat-card-actions>
            </div>

      </form>
  </mat-card-content>
</mat-card>
