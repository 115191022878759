

export class QuestionnaireConfiguration {
    id?: number;
    version?: number;
    questionnaireCode?: string;
    isAuditTransactional?: boolean;
    isActivityReport?: boolean;
    businessLineId?: number;
    tasktemplateMasterDTOs?: TaskTemplateMasterDTO [];
    android?: number;
    java?: number;
    createdBy?: string;
    createdDate?: any;
    isFrontEndUpload?: boolean;
    isProductTypeEnable?: boolean;
    isGpsTracking?: boolean;
    gpsTrackingRadius?: string;
}

export class TaskTemplateMasterDTO {
    id?: number;
    version?: number; 
    displayName?: string;
    modeOfImplementation?: string;
}