import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { FormErrorModel } from 'projects/ig-core/src/lib/form/form-error.model';
import { IgFormService } from 'projects/ig-core/src/lib/form/form.service';
import { map, takeUntil } from 'rxjs/operators';
import { QuestionaireService } from 'src/app/entities/questionaire/questionaire.service';
import { QuestionnaireConfiguration } from '../questionnaire-configuration.model';
import { QuestionnaireConfigurationService } from '../questionnaire-configuration.service';


@Component({
  selector: 'app-questionnaire-configuration-editor-editor',
   templateUrl: './questionnaire-configuration-editor.component.html'
})
export class QuestionnaireConfigurationEditorComponent implements OnInit, OnDestroy {
  questionnaireConfiguration: QuestionnaireConfiguration;
  editable = true;
  isNew: boolean;
  android : string;
  android1 : any;
  java: string;
  templateMasterJavas: any[];
  templateMasterAndroids: any[];
  businessLines: any[];
  questionnaires: any[];
  tasktemplateMasterDtos: FormArray;
  
  public reportFlag:boolean=false;
  public auditFlag:boolean=false;
  public questionnaireConfigurationEditorForm: FormGroup;
  public formError: FormErrorModel;
  public formErrors = {
    'questionnaireCode': '',
    'businessLineId': '',
    'isAuditTransactional': '',
    'isActivityReport': '',
    'android': '',
    'java':'',
    'isFrontEndUpload': '',
    'isProductTypeEnable': '',
    'isGpsTracking': '',
    'gpsTrackingRadius': ''
  };

  constructor(private questionnaireConfigurationService: QuestionnaireConfigurationService,
    private questionaireService: QuestionaireService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private igFormService: IgFormService
  ) {
    
    this.activatedRoute.data
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data.questionnaireConfiguration === undefined) {
          this.questionnaireConfiguration = {};
        } else {
          this.questionnaireConfiguration = data.questionnaireConfiguration;
        }
        this.editable = data.editable;
      });
  }

  ngOnInit() {
    this.buildForm();
    this.loadBusiness();
    this.loadAllTaskTemplateMastersByModeJava();
    this.loadAllTaskTemplateMastersByModeAndroid();
    this.formError = new FormErrorModel(false, '');
    if (!this.editable) {
      this.questionnaireConfigurationEditorForm.disable();
    }
    if(this.questionnaireConfiguration.id){
      this.loadAllActiveQuestionaires();
     } else {
      this.loadActiveUniqueQuestionnaireCode();
     }
	 
  }
  ngOnDestroy(): void {
  }

  buildForm() {

    this.isNew = false;
    if (this.questionnaireConfiguration.id === undefined) {
      this.isNew = true;
    }

    // const android= this.questionnaireConfiguration.tasktemplateMasterDTOs.filter(function(element){
    //   return element.modeOfImplementation == "android";
    // })[0];

    // const java= this.questionnaireConfiguration.tasktemplateMasterDTOs.filter(function(element){
    //   return element.modeOfImplementation == "java";
    // })[0];

      this.questionnaireConfigurationEditorForm = this.formBuilder.group({
        id: [this.questionnaireConfiguration.id],
        version: [this.questionnaireConfiguration.version],
        questionnaireCode: [{value:this.questionnaireConfiguration.questionnaireCode ? this.questionnaireConfiguration.questionnaireCode : '', disabled:this.questionnaireConfiguration.id}, Validators.required],
        isAuditTransactional: [this.questionnaireConfiguration.isAuditTransactional],
        isActivityReport: [this.questionnaireConfiguration.isActivityReport],
        businessLineId: [this.questionnaireConfiguration.businessLineId, Validators.required],
        android: [this.questionnaireConfiguration.android, Validators.required],
        java: [this.questionnaireConfiguration.java, Validators.required],
        tasktemplateMasterDTOs:[''],
        isFrontEndUpload:[this.questionnaireConfiguration.isFrontEndUpload],
        isProductTypeEnable:[this.questionnaireConfiguration.isProductTypeEnable],
        isGpsTracking :[this.questionnaireConfiguration.isGpsTracking],
        gpsTrackingRadius:[this.questionnaireConfiguration.gpsTrackingRadius]
      });
      this.questionnaireConfigurationEditorForm.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe((data) => {
        this.formErrors = this.igFormService.validateForm(this.questionnaireConfigurationEditorForm, this.formErrors, true);
      });
      
    } 
 
    loadBusiness() {
      this.questionaireService.getBusinessLine().pipe(takeUntil(componentDestroyed(this))).
        subscribe(businessLineDTO => this.businessLines = businessLineDTO);
    }

    loadActiveUniqueQuestionnaireCode(){
      this.questionaireService.getAllActiveUniqueQuestionaires().pipe(takeUntil(componentDestroyed(this))).
      subscribe(questionnaire => this.questionnaires = questionnaire);
    }  
   
    loadAllActiveQuestionaires(){
      this.questionaireService.getAllActiveQuestionaires().pipe(takeUntil(componentDestroyed(this))).
      subscribe(questionnaire => this.questionnaires = questionnaire);
    }  
      
    loadAllTaskTemplateMastersByModeAndroid(){
      this.questionnaireConfigurationService.getgetAllTaskTemplateMastersByMode("Android").pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
          (res: HttpResponse<any>) => {
              this.templateMasterAndroids = res.body;    
          }
      );
    }   

    loadAllTaskTemplateMastersByModeJava(){
      this.questionnaireConfigurationService.getgetAllTaskTemplateMastersByMode("java").pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
          (res: HttpResponse<any>) => {
              this.templateMasterJavas = res.body;    
          }
      );
    } 
  
    activeReport(){
      this.reportFlag =! this.reportFlag;
    }

    activieaudit(){
      this.auditFlag =! this.auditFlag;
    }

onSubmit() {
  this.formError = new FormErrorModel(false, '');
  this.igFormService.markFormGroupTouched(this.questionnaireConfigurationEditorForm);
  if (this.questionnaireConfigurationEditorForm.valid) {
    let tasktemplateMasterDTOs = [];
    tasktemplateMasterDTOs.push(this.questionnaireConfigurationEditorForm.get('android').value);
    tasktemplateMasterDTOs.push(this.questionnaireConfigurationEditorForm.get('java').value);
    this.questionnaireConfigurationEditorForm.get('tasktemplateMasterDTOs').setValue(tasktemplateMasterDTOs);

    this.questionnaireConfigurationEditorForm.get('questionnaireCode')?.enable();
      this.questionnaireConfigurationService.saveQuestionnaireConfiguration(this.questionnaireConfigurationEditorForm.value).pipe(takeUntil(componentDestroyed(this)))
          .subscribe((response) => this.onSubmitSuccess(response),
              (response) => this.onSubmitError(response));
  } else {
      this.formErrors = this.igFormService.validateForm(this.questionnaireConfigurationEditorForm, this.formErrors, false);
      return false;
  }
}

  private onSubmitSuccess(response) {
    let msg = '';
    if (this.questionnaireConfiguration.id) {
      msg = `Updated Questionnaire Configuration ${this.questionnaireConfiguration.id} successfully`;
    } else {
      msg = `Created Questionnaire Configuration successfully`;
    }
    this.snackBar.open(msg, 'Close');
    this.goToQuestionnaireConfigurationListPage();
  }
 
  private onSubmitError(response) {
    const errorModel = this.igFormService.handleServerError(this.questionnaireConfigurationEditorForm, this.formErrors, response.error);
    if (errorModel && errorModel.isValidationError) {
      this.formErrors = errorModel.formErrors;
    }
  }

  goToQuestionnaireConfigurationListPage() {
    if (this.questionnaireConfiguration.id) {
      this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['../'], { relativeTo: this.activatedRoute });
    }
  }

}


